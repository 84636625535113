<template>
  <div>
    <!-- <section class="mt-5">
        <b-carousel :interval="4000"
                    background="#ababab">
          <b-carousel-slide :img-src="isMobile ? 'https://activaciones.asistentemovistar.com.mx/banner_movil.jpg' : 'https://activaciones.asistentemovistar.com.mx/banner.jpg'">
          </b-carousel-slide>
        </b-carousel>
    </section> -->
    <br><br>
    <div class="row  justify-content-center ">
        <div class="col-md-8">
            <br><br v-if="!isMobile">
            <h1 class="text-center" style="font-size: 40px; color: #0189f3;"><b>Control Promocionales</b></h1>
            <hr style="border-top: 4px solid #a0e05c !important;  opacity :4;">
            <!-- <h6 class="text-center mt-5" v-if="user.perfiles[0].usuario_perfil_id == 5">Plataforma para control de inventario en promocionales</h6>
            <h4 class="text-center" v-if="user.perfiles[0].usuario_perfil_id == 5"><b>Eventos y Patrocinios</b></h4>  -->
        </div> 
        <div class="col-md-12"></div>
        <div class="col-11 col-md-11">
            <h4 style="font-size: 22px;" class="text-left" v-if="user.perfiles[0].usuario_perfil_id == 2 && ocultar == 0"><br>
                <b v-if="tipo == 0">Estatus por proyecto</b>
                <b v-else>Historial de Solicitudes</b>
            </h4>  
        </div>
    </div>
    <template v-if="user.perfiles[0].usuario_perfil_id != 10">
        <template v-if="user.perfiles[0].usuario_perfil_id == 2">
            <!-- <div class="row  justify-content-center "  v-if="ocultar == 0"> 
                <div class="col-8"></div>
                <div class="col-4" style="margin-top: -40px; margin-left: -17px;">
                   <button  class="btn btn-light float-center btn-sm" type="button" v-if="tipo == 0"
                    :style="isMobile ? 'font-size:17px; color:#fff; border-radius: 26px; width: 80%; height: 85%; background:#bd08e5; border-color:#bd08e5;'
                    :'font-size:17px; color:#fff; border-radius: 26px; width: 25%; height: 85%; background:#bd08e5; border-color:#bd08e5;'"
                    @click="tipo = 1">
                     <template v-if="tipo == 0">Historial</template>
                     <template v-else>Regresar</template>
                    </button> 
                    <u v-else :style=" isMobile ? 'cursor: pointer; margin-top: -40px; margin-left: 25px; font-size:22px; color:#3ABF30;' : 'cursor: pointer; margin-top: -40px; margin-left: 75px; font-size:22px; color:#3ABF30;'" @click="tipo = 0">Regresar</u>
                </div>
            </div> -->
            <!-- <template v-if="tipo == 0"> 
                <Registro2></Registro2>
            </template>
            <template v-if="tipo == 1"> -->
                <Listado2></Listado2>
            <!-- </template> -->
        </template>
        <template v-else>
            <Registro2 ></Registro2>
        </template>
        
    </template>
    <template v-else>
        <!-- <div class="row  justify-content-center ">
            <div class="col-md-4">
                <center>
                    <div class="row justify-content-center mt-5">
                        <div class="col-md-5">
                            <u style="color:#9B0CC5; font-size: 20px; cursor: pointer;" @click="tipo = 0">Promocionales Genéricos</u>
                        </div>
                        <div class="col-md-5">
                            <u style="color:#D57918; font-size: 20px; cursor: pointer;" @click="tipo = 1">Promocionales Premium</u>
                        </div>
                    </div>
                </center>
            </div>
        </div> -->
        <template v-if="tipo == 0">
            <div class="row  justify-content-center " v-if="ocultar == 0"> 
               <div class="col-md-6" v-if="!isMobile">
                <h3 :class="isMobile ? 'text-center mt-2' : 'text-left mt-5'" ><b>Promocionales Genéricos</b></h3>
                <br v-if="!isMobile">
               </div>
               <div class="col-md-1"></div>
               <div class="col-md-4 mt-5">
                 <center>
                    <button v-if="tips == 1"  class="btn btn-light float-center btn-sm mt-2" type="button" 
                      :style="isMobile ? 'font-size:16px; color:#fff; border-radius: 26px; width: 65%; background:#bd08e5; border-color:#bd08e5;' : 'font-size:16px; color:#fff; border-radius: 26px; width: 35%; background:#bd08e5; border-color:#bd08e5;'"  
                      @click="historialTrade()">
                      Historial
                    </button> 
                    <button v-if="tips == 0" :class="isMobile ? 'btn btn-light float-center btn-sm mt-2' : 'btn btn-light float-right btn-sm mt-2 ml-5'" type="button" 
                      :style="isMobile ? 'font-size:16px; color:#fff; border-radius: 26px; width: 65%; background:#bd08e5; border-color:#bd08e5;' 
                      : 'font-size:16px; color:#fff; border-radius: 26px; width: 55%; background:#bd08e5; border-color:#bd08e5;'"  
                      @click="tipo = 1, tipo3 = 0">
                      Promocionales PREMIUM 
                    </button> 
                 </center>
               </div>
                 
            </div> 
            <template v-if="tips == 0"> 
                <Listado></Listado>
            </template>
            <template v-if="tips == 1">
                <Registro></Registro> 
            </template>
        </template>
        <template v-if="tipo == 1">
            <div class="row  justify-content-center " v-if="ocultar == 0"> 
               <div class="col-md-6" v-if="!isMobile">
                <h3 :class="isMobile ? 'text-center mt-2' : 'text-left mt-5'" ><b>Promocionales PREMIUM</b></h3>
                <br v-if="!isMobile">
               </div>
               <div class="col-md-1"></div>
               <div class="col-md-4 mt-5">
                 <center>
                    <button v-if="tipo3 == 1"  class="btn btn-light float-center btn-sm mt-2" type="button" 
                      :style="isMobile ? 'font-size:16px; color:#fff; border-radius: 26px; width: 65%; background:#bd08e5; border-color:#bd08e5;' : 'font-size:16px; color:#fff; border-radius: 26px; width: 35%; background:#bd08e5; border-color:#bd08e5;'"  
                      @click="historialTradePremium()">
                      Historial
                    </button> 
                    <button v-if="tipo3 == 0" :class="isMobile ? 'btn btn-light float-center btn-sm mt-2' : 'btn btn-light float-right btn-sm mt-2 ml-5'" type="button" 
                      :style="isMobile ? 'font-size:16px; color:#fff; border-radius: 26px; width: 65%; background:#f2b711; border-color:#f2b711;' :
                       'font-size:16px; color:#fff; border-radius: 26px; width: 55%; background:#f2b711; border-color:#f2b711;'"  
                      @click="tipo = 0">
                      Promocionales Genéricos 
                    </button> 
                 </center>
               </div>
                 
            </div> 
            <!-- <div class="row  justify-content-center " v-if="ocultar == 0">
               <div class="col-md-4">
                <h3 :class="isMobile ? 'text-center mt-2' : 'text-left mt-2'"  ><br><b>Promocionales PREMIUM</b></h3>
                <br>
               </div>
               <div class="col-md-4 mt-5">
                 <center>
                    <button v-if="tipo3 == 0" class="btn btn-light float-center btn-sm mt-5 ml-5" type="button" 
                        :style="isMobile ? 'font-size:14px; color:#fff; border-radius: 26px; width: 35%; background:#bd08e5; border-color:#bd08e5;' : 'font-size:16px; color:#fff; border-radius: 26px; width: 45%; background:#bd08e5; border-color:#bd08e5;'"  @click="tipo3 = 1">
                      Historial
                    </button>
                    <button  class="btn btn-light float-center btn-sm mt-5 ml-5" type="button" 
                        :style="isMobile ? 'font-size:14px; color:#000; border-radius: 26px; width: 45%; background:#edb602; border-color:#edb602;' : 'font-size:16px; color:#000; border-radius: 26px; width: 45%; background:#edb602; border-color:#edb602;'"  @click="tipo = 0">
                      Promocionales Genéricos 
                    </button>
                    <br><br>
                    <u  v-if="tipo3 == 1" style="cursor: pointer;  margin-left: 40px; font-size:22px; color:#3ABF30;"   @click="tipo3 = 0">
                       Regresar 
                    </u> 
                 </center> 
               </div>
            </div>   -->
            <template v-if="tipo3 == 0"> 
                <Listado3></Listado3> 
            </template>
            <template v-if="tipo3 == 1">
               <Registro3></Registro3>
            </template>
        </template>
    </template>
    <!-- <template v-else-if="user.perfiles[0].usuario_perfil_id != 16">
        <template v-if="user.perfiles[0].usuario_perfil_id == 13">
            <Listado ></Listado>
        </template>
        <template v-else>
            <Registro></Registro>
        </template>        
    </template> -->

  </div>
</template>
<script> 
import Registro from "./Registro.vue"
import Registro2 from "./Registro2.vue"
import Registro3 from "./Registro3.vue"
import Listado from "./Listado.vue"
import Listado2 from "./Listado2.vue"
import Listado3 from "./Listado3.vue" 
export default {
    name:"home",
    components:{ 
        Listado,
        Registro2,
        Registro3,
        Listado2,
        Listado3,
        Registro
    },
    data() {
        return {
          errors:[],
          loader:false,
          but:1,
          isMobile: this.$mobile(),
          tipo:0,
          tipo3:0,
          ocultar:0,
          tips:0
        }
    },
    methods:{
        butAccion(id){
            this.but = id;
        },
        historialTrade(){
            this.tips = 0;
            this.$bus.$emit("historialTra");
        },
        historialTradePremium(){
            this.tipo3 = 0;
            this.$bus.$emit("historialTradePremium");
        }, 
    },
    computed:{
        user() {
            return this.$store.getters.getInfoUser;
        },
    },
    created(){
        this.$bus.$on("menuAct", () => {
            this.but = 2;
        });

       this.$bus.$on("menuOculta", () => {
            this.ocultar = 1;
        });

        this.$bus.$on("menuMuestra", () => {
            this.ocultar = 0;
        });

         this.$bus.$on("menuRegistro", () => {
            this.tips = 1; 
        });

        this.$bus.$on("menuRegistro2", () => { 
            this.tipo3 = 1;
        });
     }
}
</script>

<style>

</style>