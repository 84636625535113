<template>
  <div >
    <br><br>
    <!-- <hr> -->
    <b-form @submit="store">
        <div class="row justify-content-center">
            <div class="col-md-12 col-11 mt-2" >
                <div class="row justify-content-center">
                    <div class="col-md-7">
                       <div class="row ">      
                            <div class="col-md-4 mt-1 text-center"> 
                                <br v-if="isMobile">
                               <label for="" style="font-size: 17px;">Data del Líder</label>
                               <b-form-input class="mt-2" v-model="responsable" placeholder="Escribe responsable del proyecto"  type="text"/>  
                                <small v-if="errors.includes('responsable')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>        
                            </div> 
                            <div class="col-md-4 mt-1 text-center">
                                <br v-if="isMobile">
                               <label for="" style="font-size: 17px;">Región</label>
                               <select class="form-control mt-2" v-model="region"  :style="cadena.length == 0 ? 'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;' : 'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;'">
                                    <option value="" selected disabled>Selecciona</option>
                                    <option value="NORTE" >NORTE</option>
                                    <option value="SUR" >SUR</option>
                                    <option value="OCCIDENTE" >OCCIDENTE</option>
                                    <option value="CENTRO" >CENTRO</option>
                                </select>  
                                <small v-if="errors.includes('region')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                            
                            </div>   
                            <div  class="col-md-4 mt-1 text-center">
                               <br v-if="isMobile">
                               <label for="" style="font-size: 17px;">Área / Canal(es)</label>
                               <select class="form-control mt-2" v-model="canal"   :style="cadena.length == 0 ? 'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;' : 'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;'">
                                    <option value="" selected disabled>Selecciona</option>
                                    <option value="ImagenPDV" >ImagenPDV</option>
                                    <option value="Eventos y Patrocinios" >Eventos y Patrocinios</option>
                                    <!-- <option value="TradeMKT" >TradeMKT</option> -->
                                    <option value="Propio" >Propio</option>
                                    <option value="Especialista" >Especialista</option>
                                    <option value="Retail" >Retail</option>
                                </select>  
                                <small v-if="errors.includes('canal')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                            
                            </div>    
                            <div class="col-md-4 text-center mt-5" ></div>
                            <div class="col-md-4 text-center mt-5" ></div>
                            <div class="col-md-4 text-center mt-5" > 
                                <template v-if="canal == 'Retail'">
                                    <br>
                                    <label for="" style="font-size: 17px;">Cadena(s)</label>
                                    <multiselect placeholder="Elige una opción" select-label="" deselectLabel="" selectedLabel="" 
                                                    :multiple="true" 
                                                    :close-on-select="false"
                                                    v-model="cadena"
                                                    :options="arrayCadena"
                                                    class="mt-2" >
                                        </multiselect> 
                                        <small v-if="errors.includes('cadena')" class="text-danger mt-2 text-left">
                                            <i class="fas fa-exclamation-circle"></i>
                                            Este campo es obligatorio
                                        </small>         
                                </template>             
                            </div>   
                           <div class="col-md-4 mt-5 text-center">
                                <br>
                               <label for="" style="font-size: 17px;">Proyecto</label>
                               <b-form-input class="mt-2" v-model="proyecto" placeholder="Escribe el proyecto"  type="text"/>  
                                <small v-if="errors.includes('proyecto')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                             
                            </div>
                            <div  class="col-md-4 text-center mt-5" >
                              <br>
                                <label for="" style="font-size: 17px;">Selecciona el promocional</label>
                                <select class="form-control mt-2" v-model="tipo_promocional" :disabled="tipo_promocional != ''" @change="meterDetalle(1)"  style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                    <option value=""  disabled>Tipo de promocional</option>
                                    <option v-for="pro in arrayPromo" :key="pro.id" :value="pro.id" :disabled="pro.existente == 0">{{pro.nombre}}</option>
                                </select>
                                <small v-if="errors.includes('tipo_promocional')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>   
                            </div> 
                            <div class="col-md-4 text-center mt-5">
                               <br>
                               <label for="" style="font-size: 17px;">Cantidad</label>
                                <b-input-group> 
                                   <b-form-input class="mt-2" placeholder="Cantidad" v-model="cantidad"    type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>         
                                    <b-input-group-append class="mt-2">
                                        <b-button style="color:#fff; background:#0189f3;  border-color: #0189f3; font-size:17px;" variant="outline-secondary"  @click="meterDetalle(2)">Agregar</b-button>  
                                    </b-input-group-append>
                                </b-input-group>
                                <small v-if="errors.includes('cantidad')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>              
                            </div> 
                            <div class="col-md-4 text-center mt-5"></div>
                            <div class="col-md-8 text-center mt-5">
                               <small v-if="errors.includes('enviaPromo')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                   Da clic en agregar promocional para terminar el registro
                                </small> 
                                <small v-if="errors.includes('agregaCant')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                   Da clic en agregar cantidad para poder agregar un nuevo promocional
                                </small>    
                            </div>
                            <div class="col-md-12 mt-4">
                                 <div class="row">  
                                    <div v-for="(det, index) in arrayDet" :key="det.id" :value="det.id"  class="col-md-4 col-12 mt-5"> 
                                        <div :class="isMobile ? 'row  justify-content-center' : 'row'" >  
                                            <div class="col-md-1"></div>
                                            <div class="col-4">
                                                <img :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/' + det.foto" alt="Image" width="140"> 
                                            </div>
                                            <div class="col-1 mt-5">
                                                <u style="cursor: pointer; color:#ff0000;" @click="eliminaDet(index)">Eliminar</u>
                                            </div>
                                            <div class="col-12"></div>
                                            <div :class="isMobile ? 'col-12 mt-5 text-center' : 'col-12 mt-5 text-left'" v-if="det.visualiza == 0">  
                                                <!-- {{det.proyecto}}<br> 
                                                {{det.lider}}<br> 
                                                {{det.region}}<br> 
                                                {{det.canal}}<br> 
                                                <template v-if="det.cadena != 0">
                                                    <b >Cadena(s):</b>
                                                    <div class="row">  
                                                        <div v-for="can in det.cadena.split(',')" :key="can.id" :value="det.id" class="col-8">
                                                            {{can}}
                                                        </div>
                                                    </div>
                                                    <br> 
                                                </template>  -->
                                                <br>
                                                <button  class="btn btn-light float-center btn-sm" type="button"
                                                style="color:#000; border-radius: 26px; width: 70%; background:#edb602; border-color:#edb602; font-size:17px;">
                                                    <b>Cantidad solicitada:  {{det.cantidad}}</b>
                                                </button><br>
                                                <b class="mt-2" style="color: #039cf3;">{{det.nombre}}</b> <span style="color: #0189f3;">- Disponible {{det.existente}} de {{det.total}}</span>  
                                                <!-- <p class="mt-1" style="color: #0189f3; font-size: 15px; ">Disponible {{det.existente}} de {{det.total}} </p> -->
                                            </div>
                                            <div :class="isMobile ? 'col-12 mt-5 text-center' : 'col-12 mt-5 text-left'" v-if="det.visualiza == 1">  
                                                <b class="mt-2" style="color: #0189f3;">{{det.nombre}}</b> <span style="color: #0189f3;">- Disponible {{det.existente}} de {{det.total}}</span>  
                                            </div>
                                        </div> 
                                    </div>      
                                 </div> 
                            </div>
                       </div>
                   </div>
                 </div>
            </div>
            <div class="col-md-7 mt-5 text-center"> 
                <br>   
                <button  class="btn btn-primary float-center btn-sm mt-4" type="button" :style="isMobile ? 'font-size:17px; border-radius: 26px; width: 45%;' : 'font-size:17px; border-radius: 26px; width: 15%;'"
                 :disabled="!tipo_promocional"  @click="vaciaData()">
                    <i class="fas fa-plus"></i> Promocional
                </button> 
                <button :disabled="loader" class="btn btn-success float-center btn-sm mt-3 ml-4" type="submit" 
                :style="isMobile ? 'border-radius: 26px; width: 45%; background: #7eb841; border-color: #7eb841; font-size:17px;' 
                :'border-radius: 26px; width: 15%; background: #7eb841; border-color: #7eb841; font-size:17px;'">
                    <span v-if="!loader">
                        Enviar Solicitud
                    </span>
                    <span v-else>
                    <b-spinner small></b-spinner> Solicitando...
                    </span>
                </button>
            </div> 
        </div>  
    </b-form>  
    <br><br><br><br><br>
  </div>
</template>
<script> 
// import moment from 'moment';
import Swal from 'sweetalert2' 
// import $ from "jquery"
export default {
    name:"Registro", 
     data() {
        return {
          isMobile: this.$mobile(),
          errors:[],
          loader:false,
          tipo_promocional:"",
          proyecto:"",
          cantidad:"",
          responsable:"",
          canal:"",
          arrayPromo:[],
          arrayDet:[],
          arrayString:[],
          region:"" ,
          cadena:[],
          arrayCadena:['Soriana','Chedraui','FAMSA','CIMACO','Waldos','Suburbia','Elektra','Coppel','Ishu'],
          agregaCant:0
       }
     },  
     computed: {
        user() {
            return this.$store.getters.getInfoUser;
        },
     },
     methods:{
       store(e){
            e.preventDefault();
            this.loader = true;
            this.errors = [];  
            if (this.arrayDet.length == 0) {
                 this.loader = false;
                 this.errors.push('tipo_promocional');  
            }if (!this.proyecto){
                this.loader = false;
                this.errors.push('proyecto');  
            }if (!this.cantidad){
                this.loader = false;
                this.errors.push('cantidad');  
            }if (!this.region){
                this.loader = false;
                this.errors.push('region');  
            }if (!this.responsable){
                this.loader = false;
                this.errors.push('responsable');  
            }if (!this.canal){
                this.loader = false;
                this.errors.push('canal');  
            }if (this.canal == 'Retail' && this.cadena.length == 0 ){
                this.loader = false;
                this.errors.push('cadena');  
            }if (this.agregaCant == 0){ 
                this.loader = false;
                this.errors.push('agregaCant');  
            }if(this.errors.length == 0){
                let model = this.prepareModel();
                this.$api.post("control_promo/store", model).then(
                response => {
                    this.loader = false; 
                    if (this.isMobile) {
                        Swal.fire(
                        "List@",
                        "Promocional enviado exitosamente.",
                        'success'
                        )
                    }else{
                     Swal.fire({ 
                        title: this.arrayDet.length == 1 ?  'Has completado la solicitud de Promocionales: <br><br>' + this.arrayString.join("<br>") + '<br><br>' :
                        'Has completado la solicitud de Promocionales: <br><br>' + this.arrayString.join("<br>"), 
                        imageUrl: 'https://controlpromocionales.asistentemovistar.com.mx/imgs/listo.png',
                        imageWidth: 200,
                        imageHeight: 100,
                        width: 800,
                        padding: '1.5rem',
                        color: '#fff',
                        background: 'transparent url(/imgs/fondo.png)  no-repeat', 
                        })
                    }
                        this.clear();  
                        console.log(response)
                },
                error => {
                    this.loader = false;
                    if (error.api) {
                         this.errors = error.api;
                        //  this.$swal("Aviso", "Datos requeridos.", "warning")
                    }
                }
                )
             }
        },
        prepareModel() { 
             var model = new FormData();
                model.append("tipo_registro", "Solicitud de Promocionales"); 
                model.append("perfil", "Líder"); 
                model.append("creador", this.user.mrt); 
                model.append("usuario_responsable", this.user.nombre); 
                model.append("responsable", this.user.nombre); 
                model.append("detalle", JSON.stringify(this.arrayDet));
                model.append("proyecto", this.proyecto); 
                model.append("cantidad_entregada", this.cantidad); 
                model.append("responsable", this.responsable); 
                model.append("area", this.canal); 
                model.append("region", this.region); 
                model.append("cadena", this.cadena.toString()); 
                model.append("fecha_entrega", ""); 
                model.append("estatus", 2); 
                return model;
        },
        traePromo(){ 
           var url= 'control_promo/promocionales?mrt='+ this.user.mrt + '&val=1';
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayPromo = data.data.list;
                        this.region = data.data.region;
                    }
                );
            
        },
        clear(){
            this.tipo_promocional = "";
            this.proyecto = "";
            this.cantidad = "";
            // this.responsable = "";
            this.canal = "";
            this.arrayDet = [];
            this.arrayString = [];
            // this.region = "";
            this.cadena = [];
            this.traePromo();
        },
        encuentra(id){
            var sw=0;
            for(var i=0;i<this.arrayDet.length;i++){
                if(this.arrayDet[i].id == id && this.arrayDet[i].visualiza == 0){
                    sw=true;
                }
            }
            return sw;
        },
        borraAnt(id){
            for(var i=0;i<this.arrayDet.length;i++){
                if(this.arrayDet[i].nombre == id)
                { 
                     this.arrayDet.splice(i, 1);
                }
            } 
        },
        meterDetalle(id){ 

            if(this.encuentra(this.tipo_promocional) == true){
                Swal.fire({
                    icon: 'error',
                    title: 'Aviso',
                    text: 'Ese Promocional ya se encuentra agregado!' 
                    }) 
                    this.tipo_promocional = "";
            }else{ 
                // this.arrayDet = [];
                for (let i = 0; i < this.arrayPromo.length; i++)
                {
                     if (this.arrayPromo[i].id == this.tipo_promocional ) {
                        if (id == 1) {
                                this.arrayDet.push({
                                        id: this.arrayPromo[i]['id'],
                                        nombre: this.arrayPromo[i]['nombre'], 
                                        foto: this.arrayPromo[i]['foto'],
                                        existente: this.arrayPromo[i]['existente'],
                                        total: this.arrayPromo[i]['cantidad'],
                                        visualiza:1
                                    });  
                        }else{

                           this.errors = [];  
                            if (this.arrayDet.length == 0) {
                                this.loader = false;
                                this.errors.push('tipo_promocional');  
                            }if (!this.proyecto){
                                this.loader = false;
                                this.errors.push('proyecto');  
                            }if (!this.cantidad){
                                this.loader = false;
                                this.errors.push('cantidad');  
                            }if (!this.region){
                                this.loader = false;
                                this.errors.push('region');  
                            }if (!this.responsable){
                                this.loader = false;
                                this.errors.push('responsable');  
                            }if (!this.canal){
                                this.loader = false;
                                this.errors.push('canal');  
                            }if (this.canal == 'Retail' && this.cadena.length == 0 ){
                                this.loader = false;
                                this.errors.push('cadena');  
                            }if(this.errors.length == 0){
                        
                                if (parseInt(this.cantidad) > parseInt(this.arrayPromo[i]['existente'])) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Aviso',
                                    text: 'La cantidad es mayor al stock de promocionales' 
                                    })  
                                }else{
                                    this.agregaCant = 1;
                                    this.borraAnt(this.arrayPromo[i]['nombre']);
                                    this.arrayDet.push({
                                        id: this.arrayPromo[i]['id'],
                                        nombre: this.arrayPromo[i]['nombre'], 
                                        foto: this.arrayPromo[i]['foto'],
                                        existente: this.arrayPromo[i]['existente'],
                                        total: this.arrayPromo[i]['cantidad'],
                                        proyecto: this.proyecto,
                                        cantidad: this.cantidad,
                                        lider: this.responsable,
                                        canal: this.canal,
                                        visualiza:0,
                                        region: this.region,
                                        cadena: this.canal == 'Retail' ? this.cadena.toString() : 0,
                                        fecha_entrega: "",
                                    }); 


                                    this.arrayString.push(this.arrayPromo[i]['nombre'] + ' Cantidad: ' + this.cantidad);

                                    // this.cantidad = "";

                                    // this.vaciaData();

                                    //  Swal.fire(
                                    //     "Promocional agregado a tu solicitud",
                                    //     "Si deseas sumar un nuevo promocional llena nuevamente el formulario.",
                                    //     'success'
                                    //     )
                                } 
                            }
                        }     
                     }
                } 
                
            }
        },
        vaciarPromo(){
            this.tipo_promocional = "";
            this.canal = "";
            this.cantidad = "";
        },
        eliminaDet(index){  
          this.arrayDet.splice(index, 1); 
          this.arrayString.splice(index, 1); 
            this.cantidad = "";
            this.tipo_promocional = "";
            this.agregaCant = 0;
        },
        vaciaData(){
              this.errors = [];  
            if (!this.proyecto){ 
                this.errors.push('proyecto');  
            }if (!this.cantidad){ 
                this.errors.push('cantidad');  
            }if (!this.region){ 
                this.errors.push('region');  
            }if (!this.responsable){ 
                this.errors.push('responsable');  
            }if (!this.canal){ 
                this.errors.push('canal');  
            }if (this.canal == 'Retail' && this.cadena.length == 0 ){ 
                this.errors.push('cadena');  
            }if (this.agregaCant == 0){ 
                this.errors.push('agregaCant');  
            }if(this.errors.length == 0){
                // this.proyecto = "";
                // this.region = "";
                // this.canal = "";
                this.cantidad = "";
                this.tipo_promocional = "";
                this.agregaCant = 0;
                // this.cadena = [];  
            }
        }
    },
    mounted(){
        this.responsable = this.user.nombre;
        this.traePromo(); 
    }
}

 
</script>
<style scoped> 
  #pagi a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }


  #inde{
    z-index: 5;
  }

  .table-su{
    background: #CAF5A8;
  }

  #global {
	height: 470px;
	width: 100%; 
	overflow-y: scroll;
}
</style>