<template>
<div>
  <div class="row justify-content-center"  v-if="testigo == 0">
    <div class="col-md-11 col-11" > 
      <br v-if="isMobile">
        <template v-if="!isMobile">
          <button  class="btn btn-primary float-center btn-sm mt-2" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#bd08e5; border-color:#bd08e5; width: 8%;" 
           @click="registraPromo()">
            Nuevo envío 
          </button> 
          <button  class="btn btn-light float-center btn-sm mt-2 ml-3" type="button" :style="tab == 2 || tab == 3 ? 'font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 8%;'
           : 'font-size:16px; color:#fff; border-radius: 26px;  background:#A5A5A5; border-color:#A5A5A5; width: 10%;'" @click="tab == 2 ? tab = 3 : cambiaTab(2)">
            Enviados  
          </button> 
          <button  class="btn btn-light float-center btn-sm mt-2 ml-3" type="button" :style="tab == 1 || tab == 3 ? 'font-size:16px; color:#fff;  border-radius: 26px; background:#3ABF30; border-color:#3ABF30; width: 8%;'
            : 'font-size:16px; color:#fff; border-radius: 26px;  background:#A5A5A5; border-color:#A5A5A5; width: 10%;'"   @click="tab == 1 ? tab = 3 : cambiaTab(1)"> 
            Confirmados 
          </button>  
          <button  class="btn btn-light float-right btn-sm mt-2 ml-3" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 9%;"
           @click="descargaReporte2()">
           <i class="fas fa-download"></i> <span class="ml-2">Incidencias</span>  <b-spinner small v-if="loaderR1"></b-spinner>
          </button>  
           <button  class="btn btn-light float-right btn-sm mt-2 ml-3" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 9%;"
            @click="descargaReporte()">
            <i class="fas fa-download"></i> <span class="ml-2">Reporte</span>  <b-spinner small v-if="loaderR"></b-spinner>  
          </button>  
        </template>
        <template v-else> 
          <center>
          <button  class="btn btn-light float-center btn-sm mt-2" type="button" style="font-size:15px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 40%;"
          @click="descargaReporte2()">
          <i class="fas fa-download"></i> <span class="ml-2">Incidencias</span>  <b-spinner small v-if="loaderR1"></b-spinner>
          </button>  
          <button  class="btn btn-light float-center btn-sm mt-2 ml-2" type="button" style="font-size:15px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 40%;"
            @click="descargaReporte()">
            <i class="fas fa-download"></i> <span class="ml-2">Reporte</span>  <b-spinner small v-if="loaderR"></b-spinner>  
          </button>  
          </center> 
          <br> 
          <h3 :class="isMobile ? 'text-center mt-2' : 'text-left mt-5'"  :style="isMobile ? '' : 'margin-left: 85px;'"><b>Promocionales Genéricos</b></h3>
          <br>
          <button  class="btn btn-primary float-center btn-sm mt-2" type="button" style="font-size:15px; color:#fff; border-radius: 26px;  background:#bd08e5; border-color:#bd08e5; width: 30%;" 
           @click="registraPromo()">
            Nuevo envío 
          </button> 
          <button  class="btn btn-light float-center btn-sm mt-2 ml-3" type="button" :style="tab == 2 || tab == 3 ? 'font-size:15px; color:#fff; border-radius: 15px;  background:#039cf3; border-color:#039cf3; width: 30%;'
           : 'font-size:15px; color:#fff; border-radius: 15px;  background:#A5A5A5; border-color:#A5A5A5; width: 30%;'" @click="tab == 2 ? tab = 3 : cambiaTab(2)">
            Enviados  
          </button> 
          <button  class="btn btn-light float-center btn-sm mt-2 ml-3" type="button" :style="tab == 1 || tab == 3 ? 'font-size:15px; color:#fff;  border-radius: 15px; background:#3ABF30; border-color:#3ABF30; width: 30%;'
            : 'font-size:15px; color:#fff; border-radius: 26px;  background:#A5A5A5; border-color:#A5A5A5; width: 30%;'"   @click="tab == 1 ? tab = 3 : cambiaTab(1)"> 
            Confirmados 
          </button>   
        </template>
         <!-- <button  class="btn btn-primary float-center btn-sm mt-2" type="button" :style="tab == 2 || tab == 3 ? 'font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3;'
           : 'font-size:16px; color:#fff; border-radius: 26px;  background:#A5A5A5; border-color:#A5A5A5;'"  @click="tab == 2 ? tab = 3 : cambiaTab(2)">
            Pendiente 
          </button> 
          <button  class="btn btn-light float-center btn-sm mt-2 ml-3" type="button" :style="tab == 1 || tab == 3 ? 'font-size:16px; color:#fff;  border-radius: 26px; background:#3ABF30; border-color:#3ABF30;'
            : 'font-size:16px; color:#fff; border-radius: 26px;  background:#A5A5A5; border-color:#A5A5A5;'" @click="tab == 1 ? tab = 3 : cambiaTab(1)">
            Aprobada  
          </button> 
          <button  class="btn btn-light float-center btn-sm mt-2 ml-3" type="button" :style="tab == 0 || tab == 3 ? 'font-size:16px; color:#fff;  border-radius: 26px; background:#bd08e5; border-color:#bd08e5;'
            : 'font-size:16px; color:#fff; border-radius: 26px;  background:#A5A5A5; border-color:#A5A5A5;'"  @click="tab == 0 ? tab = 3 : cambiaTab(0)"> 
            Rechazada 
          </button>   -->

       <div class="form-group mt-3 ">
            <div class="col-md-6" >
                <div class="input-group" style="margin-left: -15px;">
                    <input type="text" v-model="buscar" class="form-control " placeholder="Buscar"> 
                </div>
            </div>
        </div>
        <div class="table-responsive mt-3">
            <table class="table table-hover table-striped mt-3" >
                <thead class="bg-primary text-white">
                <tr>
                    <!-- <th scope="col"></th> --> 
                    <th scope="col" style="font-size:17px;" class="text-center" v-if="isMobile">Proyecto</th>
                     <th scope="col" style="font-size:17px;" class="text-center" v-if="isMobile">Estatus</th>
                    <th scope="col" style="font-size:17px; " class="text-center">Manager Regional</th>
                    <th scope="col" style="font-size:17px;" class="text-center">Región</th>
                    <th scope="col" style="font-size:17px;" class="text-center">Bodega</th>
                    <!-- <th scope="col" class="text-center">Cadena(s)</th> -->
                    <th scope="col" style="font-size:17px;" class="text-center">Territorio</th>
                    <th scope="col" style="font-size:17px; width: 25%;" class="text-center">Plaza(s)</th>
                    <!-- <th scope="col" class="text-center">Cantidad</th> -->
                    <th scope="col" style="font-size:17px;" class="text-center" v-if="!isMobile">Proyecto</th>
                    <th scope="col" style="font-size:17px;" class="text-center">Fecha envío</th>
                    <th scope="col" style="font-size:17px;" class="text-center" v-if="!isMobile">Estatus</th>
                    <th scope="col" style="font-size:17px;" class="text-center" v-if="tab == 1">Fecha envío</th> 
                    <!-- <th scope="col" style="font-size:17px;" class="text-center" v-if="tab == 1">Testigo recepción</th> -->
                </tr>
                </thead>
                <tbody>
                <tr v-for="list in listado" :key="list.id" v-show="tab ==3 || list.estatus == tab"> 
                    <td class="text-center" v-if="isMobile"> 
                      <h6 style="color:#3ABF30; cursor: pointer;" >
                        <u @click="verPromo(list)">{{list.proyecto}}</u>
                        <i v-if="list.incidencia" style="color:#f2b711; cursor: pointer;" class="ml-3 fas fa-exclamation-triangle" @click="verIncidencia(list)"></i>
                      </h6> 
                    </td>  
                    <td class="text-center"  v-if="isMobile"> 
                        <template v-if="list.estatus == 0">
                            <p style="color:#E65252;">Cancelado</p> 
                        </template>
                        <template v-if="list.estatus == 1">
                            <p style="color:#3ABF30;">Confirmado</p> 
                        </template>
                        <template v-if="list.estatus == 2">
                            <p style="color:#039cf3;">Enviado</p> 
                        </template>     
                    </td> 
                    <td class="text-center">
                      {{list.usuario_responsable}}
                    </td> 
                    <td class="text-center">{{list.region}}</td>
                    <td class="text-center">{{list.bodega}}</td>
                    <td class="text-center">{{list.territorio}}</td>
                    <td class="text-center">{{list.plaza}}</td>
                    <!-- <td class="text-center"><template v-if="list.cadena != 0">{{list.cadena }}</template></td> -->
                    <td class="text-center" v-if="!isMobile">
                       <h6 style="color:#3ABF30; cursor: pointer;" >
                        <u @click="verPromo(list)">{{list.proyecto}}</u>
                        <i v-if="list.incidencia" style="color:#f2b711; cursor: pointer;" class="ml-3 fas fa-exclamation-triangle" @click="verIncidencia(list)"></i>
                      </h6>
                    </td>  
                    <!-- <td class="text-center"> 
                        <h6 style="color:#3ABF30; cursor: pointer;" @click="cambiarCant(list.id)"><u>{{list.cantidad_entregada}}</u></h6> 
                    </td> -->
                    <td class="text-center">{{list.created_at | fechaA}}</td> 
                    <td class="text-center"  v-if="!isMobile"> 
                        <template v-if="list.estatus == 0">
                            <p style="color:#E65252;">Cancelado</p> 
                        </template>
                        <template v-if="list.estatus == 1">
                            <p style="color:#3ABF30;">Confirmado</p> 
                        </template>
                        <template v-if="list.estatus == 2">
                            <p style="color:#039cf3;">Enviado</p> 
                        </template>     
                    </td> 
                    <td class="text-center" v-if="tab == 1">
                      <template v-if="list.estatus == 1">
                        <b-form-input v-if="!list.fecha_entrega" v-model="fecha" @change="cambiarFecha(list.id)"   type="date" :min="exh"/>
                        <template v-else>{{list.fecha_entrega}} </template> 
                      </template>
                      <template v-else>
                        
                      </template>
                    </td>  
                    <!-- <td class="text-center" v-if="tab == 1">
                      <h6  v-if="list.estatus == 1 && list.testigo" style="color:#3ABF30; cursor: pointer;" @click="verTestigo(list)"><u>Ver</u></h6>   
                    </td>  -->
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12" v-if="listado.length == 0" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
            <center>
                <br>
               <h4 style="color:#098FD6; marg">Por el momento no cuentas con proyectos; sube el primero dando clic en Nuevo Envío.</h4> 
                <div class="col-md-4" v-if="listado.length == 0">
                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                </div>
            </center>
        </div>
        <br><br> 
    </div> 
  </div> 

    <div class="row justify-content-center" v-else> 
    <div class="col-md-1 col-12" v-if="!isMobile"></div>
    <div class="col-md-11 col-12" >
      <!-- <u class="ml-4" v-if="isMobile" :style="isMobile ? 'cursor: pointer;  font-size:20px; color:#3ABF30;' : 'cursor: pointer;  margin-left: 975px; font-size:20px; color:#3ABF30;'" @click="regresaList()">Regresar</u> -->
      <h1 :class="!isMobile ? 'mt-3' : 'ml-4'" :style="isMobile ? 'font-size: 20px;' : 'font-size: 26px;'">Proyecto:<b> {{obj.proyecto}}</b></h1>
      <u :class="isMobile ? 'ml-4 mt-3' : ' mt-5'" :style="isMobile ? 'font-size:15px; cursor: pointer;  color:#3ABF30;' : 'cursor: pointer; font-size:18px; color:#3ABF30;'" @click="regresaList()">Regresar</u>
         <div :class=" isMobile ? 'row justify-content-center ' : 'row justify-content-center mt-5'">
           <div class="col-md-11 col-12 mt-3" >
              <b-alert variant="success" show style="color:#000;  background: #D5E4C5; border-color:#D5E4C5; font-size:15px">
                 <div class="row"> 
                   <div class="col-md-6 col-11" >
                     <b>Manager Regional:</b> {{obj.usuario_responsable}} <br> 
                     <b>Plaza(s):</b> {{obj.plaza}}
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Región:</b> {{obj.region}} <br> 
                     <b>Fecha envío:</b>  <template v-if="obj.fecha_entrega != '0000-00-00'">{{obj.fecha_entrega}}  </template>
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Bodega:</b> {{obj.bodega}}
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Territorio:</b> {{obj.territorio}}
                   </div>
                 </div>    
              </b-alert>
              <br> 
           </div>
           <div class="col-md-1"></div>
           <div class="col-md-10 col-11 row justify-content-center" >  
                  <div  v-for="det  in arrayPromo" :key="det.id"  :class="isMobile ? 'col-md-6 mt-1'  : 'col-md-4'">
                    <br v-if="!isMobile">
                    <center>
                      <h4>{{det.nombre}}</h4> 
                      <b style="color:#00A9E0; cursor: pointer;"  @click="cambiarCant(det.idpromo)">Cantidad {{det.cantidad}}</b><br>
                      <span style="color: #818181;">Disponible {{det.existente}} </span>   
                      <br><br> 
                      <img :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/' + det.foto " alt="Image" :width="isMobile ? 275 : 270"> 
                      <br><br>
                      <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
                    </center> 
                </div>   
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-10 col-11 text-center" >  
                <br> <br><br>
                <b style="font-size:20px">Estatus:
                      <template v-if="obj.estatus == 0">
                        <span style="color:#00A9E0;">Cancelado</span> 
                      </template>
                      <template v-if="obj.estatus == 1">
                        <span style="color:#00A9E0;">Confirmado</span> 
                      </template>
                      <template v-if="obj.estatus == 2">
                        <span style="color:#00A9E0;">Enviado</span> 
                      </template>
                </b> 
                <br><br> 
                <div class="row justify-content-center mt-2 " >  
                   <div class="col-md-2 col-6 text-center" v-if="obj.testigo">
                      <button  @click="verTest(obj)" class="btn btn-block btn-light float-center  mt-3" type="button"
                        style="color:#fff; border-radius: 26px;  background:#5BC500; border-color:#5BC500; font-size:14px;">
                          <b> Ver Testigo</b>
                      </button> 
                   </div> 
                </div>  
                <br><br>
            </div> 
              <div class="col-md-1"></div>
         </div>
    </div> 
   </div> 

    <b-modal id="promos" title="Testigo Recepción" hide-footer size="lg" > 
      <div class="row justify-content-center">  
        <div class="col-7">
            <!-- <b>Testigo fotografico</b> -->
            <br>
            <img crossorigin="anonymous" :src="$api.baseUriFiles+'control_promocional/' + obj.testigo" alt="Image"  :width="isMobile ? 250 : 400">
        </div>   
         <div class="col-12"><br><br></div>
      </div>  
    </b-modal>


    <b-modal id="entrega" title="Fecha de entrega" hide-footer   >
      <div class="row justify-content-center">
        <div  class="col-md-10 text-center" >
            <label for="" >Introduce la fecha de entrega</label>
            <b-form-input class="mt-2"  v-model="fecha_entrega"   type="date"/>  
            <br>
            <center>
                <button  class="btn btn-primary float-center btn-sm" type="button" style="border-radius: 26px; width: 30%;" @click="aprobarEstado()">
                  Enviar
                </button>
            </center>
        </div> 
      </div>
    </b-modal>


    <b-modal id="cantid" title="Modificar Cantidad" hide-footer   >
      <div class="row justify-content-center">
        <div  class="col-10 text-center" >
            <label for="" >Introduce la cantidad entregada</label>
            <b-form-input class="mt-2" placeholder="Cantidad" v-model="cantidad"   type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>  
            <br>
            <center>
                <button  class="btn btn-primary float-center btn-sm" type="button" style="border-radius: 26px; width: 30%;" @click="registraCantidad()">
                  Guardar
                </button>
            </center>
        </div> 
      </div>
    </b-modal>

    <b-modal id="verinci" title="Incidencia Reportada" hide-footer   >
      <div class="row justify-content-center">
        <div  class="col-md-10 " >          
            <p><b>Descripción:</b> {{obj.detalle_incidencia}}</p> 
            <b class="mt-3"  v-if="val == 0 || val == 1">Testigo:</b>
             <center v-if="val == 0 || val == 1">
               <img crossorigin="anonymous" :src="$api.baseUriFiles+'control_promocional/thumbs/' + obj.incidencia" alt="Image"  :width="isMobile ? 200 : 400">
               <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
             </center>
            <br> 
            <div class="row justify-content-center">
               <div class="col-md-6 col-12" v-if="val == 0 || val == 1">   
                <center>
                 <button  class="btn btn-primary float-center btn-sm mt-2" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#bd08e5; border-color:#bd08e5;" 
                  @click="responderIncidencia()">
                    Responder incidencia
                  </button> 
                </center>
               </div>  
               <div class="col-md-6 col-12" v-if="val == 0 || val == 1">  
                <center> 
                  <button  class="btn btn-light float-center btn-sm mt-2" type="button" style="font-size:16px; color:#fff;  border-radius: 26px; background:#3ABF30; border-color:#3ABF30;"
                    @click="solucionarIncidencia()"> 
                      Solucionar incidencia   <b-spinner small v-if="loaderR"></b-spinner>
                    </button>  
                </center>
               </div>  
               <div class="col-md-12 mt-4" v-if="val == 1">
                  <b-form-textarea
                    id="textarea" 
                    placeholder="Incidencia..."
                    rows="3"
                    max-rows="6"
                    v-model="incidencia"
                  ></b-form-textarea>
                  <center>
                   <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
                    <button  class="btn btn-primary float-center mt-2" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3;  width: 30%;" 
                     @click="enviarIncidencia()">
                      Enviar <b-spinner small v-if="loaderR"></b-spinner>
                    </button> 
                  </center>
               </div>
              <div class="col-md-12 mt-1" v-if="val == 2">
                <center>
                  <img src="https://controlpromocionales.asistentemovistar.com.mx/imgs/check.png" alt="Image" :width="isMobile ? 125 : 120">   
                   <br>
                   <h4 class="mt-4">Incidencia solucionada</h4>
                   <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
                   <button  class="btn btn-primary float-center mt-4" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 30%;" 
                    @click="cerrarTest()">
                      OK
                    </button> 
                  </center>
               </div>
               <div class="col-md-12 mt-1" v-if="val == 3">
                <center>
                  <img src="https://controlpromocionales.asistentemovistar.com.mx/imgs/check.png" alt="Image" :width="isMobile ? 125 : 120">   
                   <br>
                   <h4 class="mt-4">Mensaje enviado</h4>
                   <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
                   <button  class="btn btn-primary float-center mt-4" type="button" style="font-size:16px; color:#fff; border-radius: 26px;  background:#039cf3; border-color:#039cf3; width: 30%;" 
                    @click="cerrarTest()">
                      OK
                    </button> 
                  </center>
               </div>
            </div>
        </div> 
      </div>
    </b-modal>
</div>
</template>
<script>
import Swal from 'sweetalert2' 
import moment  from 'moment'; 
export default {
 name:"Listado", 
  data() {
    return {
        arrayList:[],
        buscar:"",
        arrayPromo:[],
        motivo:"",
        idpromo:0,
        cantidad:"",
        fecha:"",
        exh:moment().format("YYYY-MM-DD"),
        tab:3,
        obj:{},
        comentario_seccion:"",
        isMobile: this.$mobile(),
        loaderComent:false,
        arrayComent:[],
        fecha_entrega:"",
        identrega:"",
        testigo:0,
        arrayPros:[],
        idedit:0,
        loaderR:false,
        loaderR1:false,
        incidencia:"",
        val:0
    }
  },
    filters: {
        fechaA:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        fechaFormato:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD  LTS')
            }
        },
    },
  computed:{
    user() {
        return this.$store.getters.getInfoUser;
    },
    listado(){
        
            if (this.buscar == "") {          
                return this.arrayList;
            }else{
                return   this.arrayList.filter(item => {
                        return  item.tipo_registro?.toLowerCase().includes(this.buscar.toLowerCase()) ||
                                item.responsable?.toLowerCase().includes(this.buscar.toLowerCase()) ||
                                item.canal?.toLowerCase().includes(this.buscar.toLowerCase()) ||
                                item.proyecto?.toLowerCase().includes(this.buscar.toLowerCase()) || 
                                item.created_at?.toLowerCase().includes(this.buscar.toLowerCase()) 
                        ;
                });
            }  
        

    },
  },
  methods: {   
    responderIncidencia(){
      this.val == 0 ? this.val = 1 : this.val = 0;
      window.scrollTo(0,900);
    },
    verIncidencia(item){
      this.obj = item; 
      this.val = 0;
      this.$bvModal.show('verinci');
    }, 
    cerrarTest(){  
      this.$bvModal.hide('verinci');
    },
    enviarIncidencia(){
      this.loaderR = true;
      var url= 'control_promo/responderincidencia?id='+ this.obj.id + '&incidencia=' + this.incidencia;
            this.$api.get(url).then(
                    ({data}) => {
                      console.log(data);
                        this.val = 3;
                        this.loaderR = false;
                        this.listadoAct(); 
                    }
                );
    },
    solucionarIncidencia(){
      this.loaderR = true;
      var url= 'control_promo/solucionarincidencia?id='+ this.obj.id;
            this.$api.get(url).then(
                    ({data}) => {
                      console.log(data);
                        this.val = 2;
                        this.loaderR = false;
                        this.listadoAct(); 
                    }
                );
    },
    descargaReporte(){  
        Swal.fire({
                title: 'Descargar reporte base de datos',
                showCancelButton: true,
                confirmButtonColor: '#3ABF30',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                   this.loaderR = true;
                      this.$fileDownload(
                          this.$api.baseUri + 'control_promo/excel?tipo=Solicitud de Promocionales',
                          "reporte_promocionales" + this.$getDate() + ".xlsx",
                      ).then(
                          () => {
                              this.loaderR = false;
                          }
                      )
                }
            }) 
    },
    descargaReporte2(){  
        Swal.fire({
                title: 'Descargar reporte incidencias',
                showCancelButton: true,
                confirmButtonColor: '#3ABF30',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                   this.loaderR1 = true;
                      this.$fileDownload(
                          this.$api.baseUri + 'control_promo/excelIncidencia?tipo=Solicitud de Promocionales',
                          "reporte_Incidencia" + this.$getDate() + ".xlsx",
                      ).then(
                          () => {
                              this.loaderR1 = false;
                          }
                      )
                }
            }) 
    },
    regresaList(){
      this.$bus.$emit("menuMuestra")
      this.testigo = 0;
      this.listadoAct();
    },
    traePromo(){ 
        var url= 'control_promo/promocionales';
        this.$api.get(url).then(
                ({data}) => {
                    this.arrayPros = data.data.list;
                }
            );
        
    },
    verTest(item){
      this.obj = item;
      this.$bvModal.show('promos');
    } ,
    cambiaTab(id){
      this.tab = id;
    },  
    listadoAct(){
        var url= 'control_promo/index?mrt='+ this.user.mrt + '&perfil=Solicitud de Promocionales';
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayList = data.data.list;
                        // this.arrayPromo = data.data.promo;
                    }
                );
    },
    verPromo(item){
      this.obj = item;
        var url= 'control_promo/promos?id='+ item.id;
            this.$api.get(url).then(
                    ({data}) => { 
                        this.arrayPromo = data.data.promos;
                        this.arrayComent = data.data.comentario;
                        this.testigo = 1;
                        this.$bus.$emit("menuOculta")
                        //  this.$bvModal.show('promos');
                    }
                );
    },
    fechaEstado(id){
      this.identrega = id;
      this.$bvModal.show('entrega');
    },
    aprobarEstado(){
        if (!this.fecha_entrega) {
                Swal.fire({
                      icon: 'error',
                      title: 'Aviso',
                      text: 'Datos requeridos' 
                      }) 
        } else {
            Swal.fire({
                title: '¿Estás seguro de cambiar el estatus?',
                showCancelButton: true,
                confirmButtonColor: '#3ABF30',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                    var url= 'control_promo/estatus?id='+ this.identrega + '&est='+ 1 + '&fecha='+ this.fecha_entrega;
                    this.$api.get(url).then(
                            ({data}) => { 
                              this.obj = data.data;
                              this.tab = 1;   
                              // this.testigo = 0;
                              this.motivo = "";
                                console.log(data); 
                                 this.$bvModal.hide('entrega');
                                //  this.$bvModal.hide('promos');
                                this.listadoAct(); 
                                this.verPromo(this.obj);
                                Swal.fire(
                                    "List@",
                                    "Promocional ha sido cambiado en estatus exitosamente.",
                                    'success'
                                    )
                            }
                        );             
                }
            })
        }
    },
    denegarEstado(id){
            Swal.fire({
                title: '¿Estás seguro de cambiar el estatus?',
                showCancelButton: true,
                confirmButtonColor: '#3ABF30',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                    var url= 'control_promo/estatus?id='+ id + '&est='+ 0;
                    this.$api.get(url).then(
                            ({data}) => { 
                              this.obj = data.data;
                              this.tab = 0;   
                              // this.testigo = 0;
                              this.motivo = "";
                                console.log(data); 
                                // this.$bvModal.hide('promos');
                                this.listadoAct();  
                                this.verPromo(this.obj);
                                Swal.fire(
                                    "List@",
                                    "Promocional ha sido cambiado en estatus exitosamente.",
                                    'success'
                                    )
                            }
                        );             
                }
            })
    },
    eliminaPromo(id){
            Swal.fire({
                title: '¿Estás seguro de eliminar el promocional?',
                showCancelButton: true,
                confirmButtonColor: '#3ABF30',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                    var url= 'control_promo/eliminapromo?id='+ id;
                    this.$api.get(url).then(
                            ({data}) => { 
                              this.tab = 3;   
                              // this.testigo = 0;
                              this.motivo = "";
                                console.log(data); 
                                // this.$bvModal.hide('promos');
                                this.listadoAct();  
                                this.verPromo(this.obj);
                                Swal.fire(
                                    "List@",
                                    "Promocional ha sido eliminado exitosamente.",
                                    'success'
                                    )
                            }
                        );             
                }
            })
    },
    cambiarCant(id){
        this.idedit = id; 
        this.cantidad = "";
        if (this.obj.estatus == 2)
        {
           this.$bvModal.show('cantid');
        }
       
    },
    registraCantidad(){
        if (!this.cantidad) {
                Swal.fire({
                      icon: 'error',
                      title: 'Aviso',
                      text: 'Cantidad es requerida' 
                      }) 
        } else {
            var url= 'control_promo/cantidad?id='+ this.idedit + '&cantidad='+ this.cantidad;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data); 
                        // this.obj.cantidad_entregada = this.cantidad;
                        this.$bvModal.hide('cantid'); 
                        this.idedit = 0;
                        this.cantidad = "";
                        // this.testigo = 0;
                        this.listadoAct();
                        this.verPromo(this.obj);
                        
                        Swal.fire(
                            "List@",
                            "Cantidad ha sido cambiada exitosamente.",
                            'success'
                            )
                    }
                );       
        }
    },
    cambiarFecha(id){
         var url= 'control_promo/fecha?id='+ id + '&fecha='+ this.fecha;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);  
                        this.fecha = "";
                        this.listadoAct(); 
                        Swal.fire(
                            "List@",
                            "Fecha ha sido cambiada exitosamente.",
                            'success'
                            )
                    }
                );      
    },
    enviarComentario(){
           if (!this.comentario_seccion)
            {
              Swal.fire({
                      icon: 'error',
                      title: 'Aviso',
                      text: 'Comentario es requerido' 
                      }) 
            }else{
                this.loaderComent = true;        
                
                let model = this.prepareModelComent()
                    this.$api.post('control_promo/comentarios', model).then( response => {
                            this.comentario_seccion = "";  
                            this.loaderComent = false;  
                            this.arrayComent = response.data.data.comentario; 
                            Swal.fire(
                            "List@",
                            "Comentario se envió exitosamente.",
                            'success'
                            )                   
                        }).catch(error => {
                            this.loaderComent = false;
                            console.log(error);
                        });
            }
    },
    prepareModelComent(){
      let model = new FormData(); 
        model.append("comentario", this.comentario_seccion) 
        model.append("mrt", this.user.mrt)
        model.append("idpromo", this.obj.id)
        model.append("envia", this.user.nombre)
        model.append("perfil", "Trade Marketing")
        return model;
    },
    registraPromo(){
       this.$bus.$emit("menuRegistro")
    }
  },
  mounted(){
    this.listadoAct(); 
    this.traePromo();
  },
  created(){
   this.$bus.$on("historialTra", () => {
         this.listadoAct(); 
         this.traePromo();
        });
  }
}

</script>
<style scoped>
  #hover:hover{
    background:#B2DAFF;
  }

  #tabsCapturaPop .active {
    background: none;
    color: var(--color-primary);
    transition: all 300ms ease;
  }

  #tabsCapturaPop a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }
  #tabsCapturaPop a i {
    transition: all 300ms ease;
    animation-duration: 0.3s;
    font-size: 10px;
  }

  #tabsCapturaPop #capturaBtnReporte .active {
    background: #00a9e0;
    color: white;
  }

  #tabsCapturaPop #capturaBtnReporte {
    margin: 0 20px 0 20px !important;
  }
  
  .input-nav-search::placeholder {
  color: #a8a8a8;
  font-weight: lighter;
  font-family: TelefonicaLight, serif;
  font-size: 15px;
}


  #pagi a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }


.filepond--drop-label {
     background-color: #00a8f1 !important;
    box-shadow: 0px 10px 10px -6px black;
    cursor: pointer;
    color: #fff;
}

.flexbox {
  align-items: center;
  display: flex;
  height: 75px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}

#global {
	height: 240px;
	width: 100%;
	border: 1px solid #FAFAFA;
	background: #FAFAFA;
	overflow-y: scroll;
}
#mensajes {
	height: auto;
}
</style>
