<template>
  <div >
    <br><br>
    <!-- <hr> -->
    <b-form @submit="store">
        <div class="row justify-content-center">
            <div class="col-md-12 col-11 mt-2" >
                <div class="row justify-content-center">
                    <div class="col-md-8">
                       <div class="row justify-content-center">      
                            <div class="col-md-4 mt-1 text-center"> 
                                <br v-if="isMobile">
                                <label for="" style="font-size: 17px;">Manager Regional</label>
                                <select class="form-control mt-2" v-model="responsable" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"  @change="traeRegion()" :disabled="arrayDet.length > 0">
                                    <option value="" selected disabled>Selecciona un Manager Regional</option>
                                    <option v-for="res in arrayResponsable" :key="res.id" :value="res" >{{res}}</option> 
                                </select>  
                                <small v-if="errors.includes('responsable')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>        
                            </div> 
                            <div class="col-md-4 mt-1 text-center">
                                <br v-if="isMobile">
                               <label for="" style="font-size: 17px;">Región</label>
                               <select class="form-control mt-2" v-model="region" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"  v-if="arrayRegion.length > 1 " :disabled="arrayDet.length > 0">
                                    <option value="" selected disabled>Selecciona</option>
                                    <option v-for="reg in arrayRegion" :key="reg.id" :value="reg" >{{reg}}</option> 
                                    <!-- <option value="NORTE" >NORTE</option>
                                    <option value="SUR" >SUR</option>
                                    <option value="OCCIDENTE" >OCCIDENTE</option>
                                    <option value="CENTRO" >CENTRO</option> -->
                                </select>  
                                 <b-form-input class="mt-2" v-model="region" placeholder="Region"  type="text" disabled v-else/>  
                                <small v-if="errors.includes('region')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                            
                            </div>   
                            <div  class="col-md-4 mt-1 text-center">
                               <br v-if="isMobile">
                               <label for="" style="font-size: 17px;">Bodega</label>
                               <select class="form-control mt-2" v-model="bodega"  style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"  v-if="arrayBodega.length > 1"  @change="traePlaza()" :disabled="arrayDet.length > 0">
                                    <option value="" selected disabled>Selecciona</option>
                                    <option v-for="bod in arrayBodega" :key="bod.id" :value="bod" >{{bod}}</option> 
                                </select>  
                                 <b-form-input class="mt-2" v-model="bodega" placeholder="Region" disabled type="text" v-else/>  
                                <small v-if="errors.includes('bodega')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                            
                            </div>    
                            <div  class="col-md-4 mt-5  text-center">
                               <br>
                               <label for="" style="font-size: 17px;">Territorio</label>
                               <select class="form-control mt-2" v-model="territorio" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;" v-if="arrayTerritorio.length > 1" :disabled="arrayDet.length > 0">
                                    <option value="" selected disabled>Selecciona</option>
                                    <option v-for="ter in arrayTerritorio" :key="ter.id" :value="ter" >{{ter}}</option> 
                                </select>  
                                 <b-form-input class="mt-2" v-model="territorio" placeholder="Region" disabled type="text" v-else/>  
                                <small v-if="errors.includes('territorio')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                            
                            </div> 
                            <div  class="col-md-4 mt-5 text-center">
                               <br>
                               <label for="" style="font-size: 17px;">Plaza(s)</label>
                               <select class="form-control mt-2" v-model="plaza" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;" v-if="arrayPlaza.length > 1" :disabled="arrayDet.length > 0">
                                    <option value="" selected disabled>Selecciona</option>
                                    <option v-for="pla in arrayPlaza" :key="pla.id" :value="pla" >{{pla}}</option> 
                                </select>  
                                <b-form-input class="mt-2" v-model="plaza" placeholder="Region" disabled type="text" v-else/>  
                                <small v-if="errors.includes('plaza')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                            
                            </div>  
                            <div  class="col-md-12 mt-1 text-center"></div>
                            <!-- <div class="col-md-4 text-center mt-5" ></div>
                            <div class="col-md-4 text-center mt-5" ></div>
                            <div class="col-md-4 text-center mt-5" > 
                                <template v-if="canal == 'Retail'">
                                    <br>
                                    <label for="" style="font-size: 17px;">Cadena(s)</label>
                                    <multiselect placeholder="Elige una opción" select-label="" deselectLabel="" selectedLabel="" 
                                                    :multiple="true" 
                                                    :close-on-select="false"
                                                    v-model="cadena"
                                                    :options="arrayCadena"
                                                    class="mt-2" >
                                        </multiselect> 
                                        <small v-if="errors.includes('cadena')" class="text-danger mt-2 text-left">
                                            <i class="fas fa-exclamation-circle"></i>
                                            Este campo es obligatorio
                                        </small>         
                                </template>             
                            </div>    -->
                           <div class="col-md-4 mt-5 text-center">
                                <br>
                               <label for="" style="font-size: 17px;">Proyecto</label>
                               <b-form-input class="mt-2" v-model="proyecto" placeholder="Escribe el proyecto"  type="text"/>  
                                <small v-if="errors.includes('proyecto')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>                             
                            </div>
                            <div  class="col-md-12 mt-1 text-center"></div>
                            <div  class="col-md-4 text-center mt-5" >
                              <br>
                                <label for="" style="font-size: 17px;">Selecciona el promocional</label>
                                <select class="form-control mt-2" v-model="tipo_promocional" :disabled="tipo_promocional != ''" @change="meterDetalle(1)"  style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                    <option value=""  disabled>Tipo de promocional</option>
                                    <option v-for="pro in arrayPromo" :key="pro.id" :value="pro.id" v-show="pro.tipo == 'Generico'">{{pro.nombre}}</option>
                                </select>
                                <small v-if="errors.includes('tipo_promocional')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>   
                               <small v-if="errors.includes('repetido')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Promocional agregado. Selecciona uno diferente.
                                </small>      
                            </div> 
                            <div class="col-md-4 text-center mt-5" id="subir">
                               <br>
                               <label for="" style="font-size: 17px;">Cantidad</label>
                                <b-input-group> 
                                   <b-form-input class="mt-2" placeholder="Cantidad" v-model="cantidad"  :disabled="tipo_promocional != '' && agregaCant == 1"  type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>         
                                    <b-input-group-append class="mt-2">
                                        <b-button style="color:#fff; background:#0189f3;  border-color: #0189f3; font-size:17px;" variant="outline-secondary" :disabled="tipo_promocional != '' && cantidad == 1"   @click="meterDetalle(2)">Agregar</b-button>  
                                    </b-input-group-append>
                                </b-input-group>
                                <small v-if="errors.includes('cantidad')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>  
                                <small v-if="errors.includes('maximo')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                   Clic en Agregar cantidad para sumar un nuevo promocional a tu envío.
                                </small>             
                            </div> 
                            <div class="col-md-4 text-center mt-5">
                               <br>
                               <label for="" style="font-size: 17px;">Fecha envío</label>
                               <b-form-input class="mt-2" v-model="fecha" placeholder="Escribe el proyecto"  type="date"/>  
                                <small v-if="errors.includes('fecha')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>  
                            </div>
                            <div class="col-md-8 text-center mt-5">
                               <small v-if="errors.includes('enviaPromo')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                   Da clic en agregar promocional para terminar el registro
                                </small> 
                                <small v-if="errors.includes('agregaCant')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                   Para envío de nuevo material da clic en agregar
                                </small>    
                            </div>
                            <div class="col-md-12 mt-4">
                                 <div class="row">  
                                    <div v-for="(det, index) in arrayDet" :key="det.id" :value="det.id"  class="col-md-4 col-12 mt-5"> 
                                        <div :class="isMobile ? 'row  justify-content-center' : 'row'" >   
                                            <div class="col-4">
                                                <img :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/' + det.foto" alt="Image" width="140"> 
                                            </div>
                                            <div class="col-1 ml-5"></div>
                                            <div class="col-1 ml-5">
                                                <u style="cursor: pointer; color:#ff0000;" @click="eliminaDet(index)">Eliminar</u>
                                            </div>
                                            <div class="col-12"></div>
                                            <div :class="isMobile ? 'col-12 mt-5 text-center' : 'col-12 mt-5 text-left'" v-if="det.visualiza == 0">  
                                                <!-- {{det.proyecto}}<br> 
                                                {{det.lider}}<br> 
                                                {{det.region}}<br> 
                                                {{det.canal}}<br> 
                                                <template v-if="det.cadena != 0">
                                                    <b >Cadena(s):</b>
                                                    <div class="row">  
                                                        <div v-for="can in det.cadena.split(',')" :key="can.id" :value="det.id" class="col-8">
                                                            {{can}}
                                                        </div>
                                                    </div>
                                                    <br> 
                                                </template>  -->
                                                <br>
                                                <button  class="btn btn-light float-center btn-sm" type="button"
                                                style="color:#000; border-radius: 26px; width: 70%; background:#edb602; border-color:#edb602; font-size:17px;">
                                                    <b>Cantidad solicitada:  {{det.cantidad}}</b>
                                                </button><br>
                                                <b class="mt-2" style="color: #039cf3;">{{det.nombre}}</b> <span style="color: #0189f3; font-size:small;">- Disponible {{det.existente}}</span>  
                                                <!-- <p class="mt-1" style="color: #0189f3; font-size: 15px; ">Disponible {{det.existente}} de {{det.total}} </p> -->
                                            </div>
                                            <div :class="isMobile ? 'col-12 mt-5 text-center' : 'col-12 mt-5 text-left'" v-if="det.visualiza == 1">  
                                                <b class="mt-2" style="color: #0189f3;">{{det.nombre}}</b> <span style="color: #0189f3; font-size:small;">- Disponible {{det.existente}}</span>  
                                            </div>
                                        </div> 
                                    </div>      
                                 </div> 
                            </div>
                       </div>
                   </div>
                 </div>
            </div>
            <div class="col-md-7 mt-5 text-center"> 
                <br>   
                <button  class="btn btn-primary float-center btn-sm mt-3" type="button" :style="isMobile ? 'font-size:17px; border-radius: 26px; width: 45%;' : 'font-size:17px; border-radius: 26px; width: 16%;'"
                 :disabled="!tipo_promocional"  @click="vaciaData()">
                    <i class="fas fa-plus"></i> Promocional
                </button> 
                <button :disabled="loader" class="btn btn-success float-center btn-sm mt-3 ml-4" type="submit" 
                :style="isMobile ? 'border-radius: 26px; width: 45%; background: #7eb841; border-color: #7eb841; font-size:17px;' 
                :'border-radius: 26px; width: 15%; background: #7eb841; border-color: #7eb841; font-size:17px;'">
                    <span v-if="!loader">
                        Enviar
                    </span>
                    <span v-else>
                    <b-spinner small></b-spinner> Enviando...
                    </span>
                </button>
            </div> 
        </div>  
    </b-form>  
    <br><br><br><br><br>

    
    
    <div class="modal fade bd-example-modal"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="materialCa">
        <div class="modal-dialog modal  modal-dialog-centered" >

            <div class="modal-content"  style="  background-image: url('https://controlpromocionales.asistentemovistar.com.mx/imgs/popup2.png');  background-size: cover;   background-repeat: no-repeat; margin-top:-20%;">
              <br><br> 
                <b-row class="justify-content-center">
                  <b-col cols="10">
                      <!-- <a style="color: #fff; cursor: pointer;" data-dismiss="modal" aria-label="Close" class="m-3 text-right">
                          <i class="fas fa-arrow-left"></i>
                          Cerrar Ventana
                      </a>
                      <br>  -->
                  </b-col>
                  </b-row>
                    <div class="modal-body" >
                      <div class="row justify-content-center">
                          <div class="col-12 text-center" style="color:#fff;" >
                            <img src="https://controlpromocionales.asistentemovistar.com.mx/imgs/listo.png"  class="img-fluid" width="120"> 
                              <br><br>
                                <h5><b>Proyecto enviado exitosamente: <br><br> </b> </h5>
                                <div  v-for="det in arrayString" :key="det.id" :value="det.id" >
                                     {{det}} <br>
                                </div>
                                <br>
                                <!-- <h5>En breve te contactaremos.</h5> -->
                                <br>  
                               <button  class="btn btn-primary float-center btn-sm mt-2" type="button" style="font-size:16px; color:#2b82c5;  background:#fff; border-color:#fff; width: 20%;" 
                                 @click="cerrarModal()">
                                    OK
                                </button> 
                                <template v-if="!isMobile"><br><br><br></template>
                          </div>
                        </div>
                    </div>
            </div>
        </div>
    </div> 
 
  </div>
</template>
<script> 
// import moment from 'moment';
import Swal from 'sweetalert2' 
import $ from 'jquery';  
import 'bootstrap';
export default {
    name:"Registro", 
     data() {
        return {
          isMobile: this.$mobile(),
          errors:[],
          loader:false,
          tipo_promocional:"",
          proyecto:"",
          cantidad:"",
          responsable:"",
          bodega:"",
          arrayPromo:[],
          arrayDet:[],
          arrayString:[],
          region:"" , 
          territorio:"",
          plaza:"",
          fecha:"",
          arrayCadena:['Soriana','Chedraui','FAMSA','CIMACO','Waldos','Suburbia','Elektra','Coppel','Ishu'],
          agregaCant:0,
          arrayResponsable:[],
          arrayRegion:[],
          arrayBodega:[],
          arrayTerritorio:[],
          arrayPlaza:[],
          valida:0 
       }
     },  
     computed: {
        user() {
            return this.$store.getters.getInfoUser;
        },
     },
     methods:{
        cerrarModal(){
            $('#materialCa').modal("hide"); 
            this.arrayString = [];
        },
       store(e){
            e.preventDefault();
            this.loader = true;
            this.errors = [];  
            if(!this.proyecto){
                this.loader = false;
                this.errors.push('proyecto');  
            }if (!this.cantidad){
                this.loader = false;
                this.errors.push('cantidad');  
            }if (!this.region){
                this.loader = false;
                this.errors.push('region');  
            }if (!this.responsable){
                this.loader = false;
                this.errors.push('responsable');  
            }if (!this.bodega){
                this.loader = false;
                this.errors.push('bodega');  
            }if (!this.territorio){
                this.loader = false;
                this.errors.push('territorio');  
            }if (!this.plaza){
                this.loader = false;
                this.errors.push('plaza');  
            }if (!this.fecha){
                this.loader = false;
                this.errors.push('fecha');  
            }if (this.agregaCant == 0){ 
                this.loader = false;
                this.errors.push('agregaCant');  
                 window.scrollTo(0,900);
            }if(this.errors.length == 0){
                let model = this.prepareModel();
                this.$api.post("control_promo/store", model).then(
                response => {
                    this.loader = false; 
                    $('#materialCa').modal("show"); 
                        this.clear();  
                           window.scrollBy(0, 500);
                        console.log(response)
                },
                error => {
                    this.loader = false;
                    if (error.api) {
                         this.errors = error.api; 
                    }
                }
                )
             }
        },
        prepareModel() { 
             var model = new FormData();
                model.append("tipo_registro", "Solicitud de Promocionales"); 
                model.append("perfil", "Trade Marketing"); 
                model.append("creador", this.user.mrt); 
                model.append("usuario_responsable", this.responsable); 
                model.append("responsable", this.responsable); 
                model.append("detalle", JSON.stringify(this.arrayDet));
                model.append("proyecto", this.proyecto); 
                model.append("cantidad_entregada", this.cantidad);  
                model.append("bodega", this.bodega); 
                model.append("plaza", this.plaza); 
                model.append("region", this.region); 
                model.append("territorio", this.territorio); 
                model.append("fecha_entrega", this.fecha); 
                model.append("estatus", 2); 
                return model;
        },
        traePromo(){ 
           var url= 'control_promo/promocionales?mrt='+ this.user.mrt + '&val=1';
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayPromo = data.data.list;
                        // this.region = data.data.region;
                        this.arrayResponsable = data.data.responsable;
                    }
                );
            
        },
        clear(){
            this.tipo_promocional = "";
            this.proyecto = "";
            this.cantidad = "";
            this.responsable = "";
            this.bodega = "";
            this.arrayDet = [];
            // this.arrayString = [];
            this.region = ""; 
            this.territorio = "";
            this.plaza = "";
            this.fecha = "";
             this.agregaCant = 0;
            this.traePromo();
        },
        encuentra(id){
            var sw=0;
            for(var i=0;i<this.arrayDet.length;i++){
                if(this.arrayDet[i].id == id && this.arrayDet[i].visualiza == 0){
                    sw=true;
                }
            }
            return sw;
        },
        borraAnt(id){
            for(var i=0;i<this.arrayDet.length;i++){
                if(this.arrayDet[i].nombre == id)
                { 
                     this.arrayDet.splice(i, 1);
                }
            } 
        },
        meterDetalle(id){ 
            this.errors = [];
            if(this.encuentra(this.tipo_promocional) == true){
                 this.errors.push('repetido');  
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Aviso',
                //     text: 'Ese Promocional ya se encuentra agregado!' 
                //     }) 
                    this.tipo_promocional = "";
            }else{ 
                // this.arrayDet = [];
                for (let i = 0; i < this.arrayPromo.length; i++)
                {
                     if (this.arrayPromo[i].id == this.tipo_promocional ) {
                        if (id == 1) {
                                this.arrayDet.push({
                                        id: this.arrayPromo[i]['id'],
                                        nombre: this.arrayPromo[i]['nombre'], 
                                        foto: this.arrayPromo[i]['foto'],
                                        existente: this.arrayPromo[i]['existente'],
                                        total: this.arrayPromo[i]['cantidad'],
                                        visualiza:1
                                    });  
                        }else{

                           this.errors = [];  
                            if(!this.proyecto){
                                this.loader = false;
                                this.errors.push('proyecto');  
                            }if (!this.cantidad){
                                this.loader = false;
                                this.errors.push('cantidad');  
                            }if (!this.region){
                                this.loader = false;
                                this.errors.push('region');  
                            }if (!this.responsable){
                                this.loader = false;
                                this.errors.push('responsable');  
                            }if (!this.bodega){
                                this.loader = false;
                                this.errors.push('bodega');  
                            }if (!this.territorio){
                                this.loader = false;
                                this.errors.push('territorio');  
                            }if (!this.plaza){
                                this.loader = false;
                                this.errors.push('plaza');  
                            }if(this.errors.length == 0){
                        
                                // if (parseInt(this.cantidad) > parseInt(this.arrayPromo[i]['existente'])) {
                                //       this.errors.push('maximo');  
                                // Swal.fire({
                                //     icon: 'error',
                                //     title: 'Aviso',
                                //     text: 'La cantidad es mayor al stock de promocionales' 
                                //     })  
                                // }else{
                                    window.scrollBy(0, 300);
                                    this.agregaCant = 1;
                                    this.borraAnt(this.arrayPromo[i]['nombre']);
                                    this.arrayDet.push({
                                        id: this.arrayPromo[i]['id'],
                                        nombre: this.arrayPromo[i]['nombre'], 
                                        foto: this.arrayPromo[i]['foto'],
                                        existente: this.arrayPromo[i]['existente'],
                                        total: this.arrayPromo[i]['cantidad'],
                                        proyecto: this.proyecto,
                                        cantidad: this.cantidad,
                                        lider: this.responsable,
                                        bodega: this.bodega,
                                        plaza: this.plaza,
                                        visualiza:0,
                                        region: this.region,
                                        territorio: this.territorio,
                                        fecha_entrega: this.fecha,
                                    }); 

                                  


                                    this.arrayString.push(this.arrayPromo[i]['nombre'] + ' Cantidad: ' + this.cantidad);

                                    // this.cantidad = "";

                                    // this.vaciaData();

                                    //  Swal.fire(
                                    //     "Promocional agregado a tu solicitud",
                                    //     "Si deseas sumar un nuevo promocional llena nuevamente el formulario.",
                                    //     'success'
                                    //     )
                                // } 
                            }
                        }     
                     }
                } 
                
            }
        },
        vaciarPromo(){
            this.tipo_promocional = "";
            this.canal = "";
            this.cantidad = "";
        },
        eliminaDet(index){  
          this.arrayDet.splice(index, 1); 
          this.arrayString.splice(index, 1); 
            this.cantidad = "";
            this.tipo_promocional = "";
            this.agregaCant = 0;
        },
        vaciaData(){
            this.errors = [];  
            if(!this.proyecto){
                this.loader = false;
                this.errors.push('proyecto');  
            }if (!this.cantidad){
                this.loader = false;
                this.errors.push('cantidad');  
            }if (!this.region){
                this.loader = false;
                this.errors.push('region');  
            }if (!this.responsable){
                this.loader = false;
                this.errors.push('responsable');  
            }if (!this.bodega){
                this.loader = false;
                this.errors.push('bodega');  
            }if (!this.territorio){
                this.loader = false;
                this.errors.push('territorio');  
            }if (!this.plaza){
                this.loader = false;
                this.errors.push('plaza');  
            }if (this.agregaCant == 0){ 
                this.loader = false;
                this.errors.push('agregaCant');   
            }if(this.errors.length == 0){
                // this.proyecto = "";
                // this.region = "";
                // this.canal = "";
                this.cantidad = "";
                this.tipo_promocional = "";
                this.agregaCant = 0;
                window.scroll(0, 900);
                // this.cadena = [];  
            }
        },
        traeRegion(){
          if(!this.responsable){
                Swal.fire({
                    icon: 'error',
                    title: 'Requerido',
                    text: 'Seleccione un Manager Regional' 
                    }) 
            }else{ 
             var url= 'control_promo/regiones?atn='+ this.responsable;
             this.$api.get(url).then(
                    ({data}) => { 
                        this.arrayRegion = data.data.regiones; 
                        // if (data.data.valida != 0) {
                            this.region = data.data.valida.region;
                            this.bodega = data.data.valida.bodega;
                            this.territorio = data.data.valida.territorio;
                            this.plaza = data.data.valida.plaza;
                            this.arrayBodega = data.data.bodega; 
                            this.arrayTerritorio = data.data.territorio; 
                            this.arrayPlaza = data.data.plaza;
                            // this.arrayPromo = data.data.promocional;  
                            this.traePlaza();
                            // this.valida = 1;
                        // }else{
                        //     this.region = "";
                        //     this.bodega = "";
                        //     this.territorio = "";
                        //     this.plaza = "";
                        //     this.arrayBodega = [];
                        //     this.arrayTerritorio = [];
                        //     this.arrayPlaza = [];
                        //     this.arrayPromo = [];
                        //     this.valida = 0;
                        // }
                        
                    }
                );
            }
        },
        traeData(){
           var url= 'control_promo/plaza?atn='+ this.responsable + '&region=' + this.region;
             this.$api.get(url).then(
                    ({data}) => { 
                      this.arrayTerritorio = data.data.territorio; 
                      this.arrayPlaza = data.data.plaza; 
                      this.arrayBodega = data.data.bodega; 
                    }
                ); 
        },
        traePlaza(){
           var url= 'control_promo/bodega?atn='+ this.responsable + '&region=' + this.region  + '&bodega=' + this.bodega;
             this.$api.get(url).then(
                    ({data}) => { 
                    //   this.arrayTerritorio = data.data.territorio; 
                        // this.arrayPlaza = data.data.plaza;
                      this.plaza = data.data.plaza.plaza;
                    //   this.arrayPromo = data.data.promocional;  
                    }
                ); 
        }
    },
    mounted(){ 
        this.traePromo();  
    }
}

 
</script>
<style scoped> 
  #pagi a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }


  #inde{
    z-index: 5;
  }

  .table-su{
    background: #CAF5A8;
  }

  #global {
	height: 470px;
	width: 100%; 
	overflow-y: scroll;
}
</style>